.download-page {
  max-width: 800px;

  .dlc-page-btn {
    position: relative;
    border: 1px solid gray;
    border-radius: 15%;
    width: 180px;
    height: 180px;
    box-sizing: border-box;
    overflow: hidden;
  
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .content {
    width: 100%;
    --color-gray: gray;

    h4 {
      border-bottom: 1px solid var(--color-gray);
      padding-bottom: 5px!important;
      margin-bottom: 10px;

      &:not(:first-child) {
        margin-top: 30px;
      }
    }

    .app-links {
      padding: 10px 10px 20px 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border: 1px solid gray;
      background-color: white;
      border-radius: 20px;

      .links {
        display: flex;
        justify-content: space-evenly;
      }

      img {
        height: 40px;

        // &:last-child {
        //   margin-left: 10px;
        // }
      }
    }

    .video-embed {
      position: relative;
      box-sizing: content-box;
      padding-top: 56.25%;
    }

    .video-embed > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: lightgray;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @media screen and (min-width: 768px) {
      & > * {
        padding: 0 80px;
      }

      & > .med {
        padding: 0 40px;
      }
      
      & > .wide {
        padding: 0;
      }
    }
  }
}
