.home-page {
  .user-info span {
    display: block;
  }

  .settings-list {
    background-color: white;
    border: 1px solid #707070;
    margin: 20px 0;
    min-height: 200px;

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 8px;
      padding: 10px 0;
      cursor: pointer;

      svg {
        color: #999999;
      }

      &:hover {
        color: #0296DE;

        svg {
          color: #0296DE;
        }
      }

      & + li {
        border-top: 1px solid #999999;
      }
    }
  }

  .cancel-account-link {
    cursor: pointer;
    font-size: smaller;
    margin-top: 20px;
    text-decoration: underline !important;
    color: black;
  }

  .allow-endline {
    white-space: pre-line;
  }
}