$gray: #999999;

.admin-page {
  display: flex;
  flex-direction: column;
}

.admin-form {
  margin-top: auto;
}

.form-title {
  color: $gray;
  text-align: center;
  margin-bottom: 10px;
}

.coupon-type-item {
  display: inline-block;
  position: relative;
  background-color: cadetblue;
  color: white;
  border-radius: 3px;
  margin: 3px;
  padding: 5px;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  .name {
    font-weight: bold;
  }

  .disabled {
    opacity: 0.5;
  }
}
