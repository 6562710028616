.settings-page {
  .plan-update-info {
    text-align: right;
    width: 80%;
    height: 75%;
    margin: auto;

    span {
      display: block;
    }

    p {
      margin: 20px 0;
    }
  }

  .list-design {
    list-style-type: none;
  }

  .warning-text {
    text-align: start;
    font-weight: normal;
  }
  .plan-list {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    .plan-item {
      margin: 10px 0;
    }
  }

  .text-link {
    cursor: pointer;
    font-size: smaller;
    margin-top: 20px;
    text-decoration: underline !important;
    color: black;
    width: fit-content;
  }
  // PayJp related css
  .payjp-form {
    position: relative;
  }

  .selector {
    display: flex;
    height: 40px;
    justify-content: space-around;
    border: 2px solid #cfcfd0;
    border-radius: 20px;
    box-shadow: inset 0px 3px 6px rgba(0, 0, 0, 0.16);
  }

  .selector-text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 50%;
    font-size: 16px;
    color: #999999;
    font-weight: bold;
    border: 0px;
    border-radius: 20px;
    background-color: transparent;
  }

  .selected-text {
    background-color: #0096de;
    color: #ffffff;
    border-radius: 20px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  }

  .top-text {
    display: flex;
    align-items: center;
    justify-content: center;
    color: gray;
    font-size: 12px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: -5px;
  }

  .benefit-text {
    display: flex;
    align-items: center;
    justify-content: center;
    color: gray;
    font-weight: bold;
    margin-top: -35px;
    margin-bottom: 5px;
    font-size: 12px;
  }

  .payjp-input {
    display: flex;
    flex-direction: column;
    height: 150px;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }

  .payjp-loader {
    position: absolute;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-color: white;
  }
  .payjp-border {
    left: 0;
    bottom: 0;
    height: 0;
    border-top-width: 0;
    color: #ececec;
    position: absolute;
    width: 100%;
    margin-bottom: -1px;
  }
  .payjp-hiddenborder {
    left: 0;
    border-style: none none solid;
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 0;
    border-color: #198fcc;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    -o-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    -o-transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    -moz-transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    margin-bottom: -1px;
  }
  .plan-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .bottom-left-text {
    font-size: 11px;
  }
  .bottom-right-text {
    font-size: 15px;
    font-weight: bold;
    white-space: nowrap;
  }
  .button-container {
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
  }
  .next-button {
    background-color: #0096de;
    color: white;
    font-size: 16px;
    border-color: transparent;
    width: 180px;
    height: 50px;
    border-radius: 35px;
  }
  .back-button {
    background-color: #999999;
    color: white;
    font-size: 16px;
    border-color: transparent;
    width: 180px;
    height: 50px;
    border-radius: 35px;
  }
}

.form-label {
  padding: 12px;
  padding-bottom: 0;
  color: #707070;
}
