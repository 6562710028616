.signup-page {
  margin-top: auto;
  margin-bottom: auto;

  .btn-primary {
    margin: 24px 0;
  }

  .terms-and-privacy {
    text-align: center;
    font-size: 12px;
  }
}