.dlc-btn-container {
  .dlc-page-btn {
    position: relative;
    border: 1px solid gray;
    border-radius: 15%;
    width: 45%;
    box-sizing: border-box;
    padding: 22.5%;
    overflow: hidden;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
