.plan-item {
  display: flex;
  cursor: pointer;
  
  .checkbox {
    display: flex;
    align-items: center;
    padding-right: 10px;
  }
  
  .plan-item-content {
    display: flex;
    border: 1px solid #707070;
    height: 64px;
    border-radius: 15px;
    background-color: #FFF;
    padding: 0 3px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  
    .left-content {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 90px;
  
      @media screen and (max-width: 360px) {
        width: 80px;
      }
  
      .plan-name {
        display: flex;
        flex-direction: column;
      }
    }
  
    .right-content {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: space-between;
      border-left: 1px solid #707070;
      padding-left: 5px;
      padding-right: 5px;
    }
  
    .font-xs {
      font-size: 9px;
    }
  
    .font-sm {
      font-size: 12px;
      align-items: center;
      justify-content: space-between;
  
      @media screen and (max-width: 360px) {
        font-size: 10px;
      }
    }
    .font-sm-nowrap {
      font-size: 12px;
      white-space: nowrap;
  
      @media screen and (max-width: 360px) {
        font-size: 10px;
        white-space: nowrap;
      }
    }
  }

  .plan-item-content-selected {
    border: 2px solid #0096DB;
  }
  .plan-item-content-disabled {
    border: 2px solid #707070;
  }
}
