$primary: #0296DE;
$gray: #999999;
$dark-gray: #707070;
$white: #FFF;
$red: #FF0000;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  a {
    text-decoration: none !important;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  .btn-primary {
    background-color: $primary;
    border-radius: 20px;
  }

  .btn-outline-primary {
    color: $primary;
    border-color: $primary;
    border-radius: 20px;

    &:hover {
      background-color: $primary;
      color: $white;
    }
  }

  .link-primary {
    color: $primary;

    &:hover {
      color: $gray;
    }
  }

  .link-secondary {
    color: $gray;

    &:hover {
      color: $primary;
    }
  }

  .link-default {
    color: $white;

    &:hover {
      color: $primary;
    }
  }
}

.col-white {
  color: $white;
}

.col-dark-gray {
  color: $dark-gray;
}

.col-primary {
  color: $primary;
}

.col-gray {
  color: $gray;
}

.col-red {
  color: $red;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.sm-content {
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
  width: 100%;
}

.page-title {
  color: $gray;
  text-align: center;
  margin-bottom: 40px;
}

.invalid-text {
  margin: 0;
  color: $red;
  font-size: 12px;
}

.full-page-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hidden {
  display: none;
}
