.login-page {
  margin-top: auto;
  margin-bottom: auto;
  --color-gray: #ced4da;

  .logo {
    text-align: center;
    margin-bottom: 20px;

    img {
      width: 100%;
    }
  }

  h4 {
    border-bottom: 1px solid var(--color-gray);
  }

  @media screen and (min-width: 600px) {
    .wide-content {
      width: 140%;
      margin-left: -20%;
    }
  }
}

.forgot-password {
  text-align: right;
  
  a {
    font-size: 12px;
  }
}