.header {
  padding: 15px;
  max-width: 800px;
  display: flex;
  justify-content: space-between;

  &.center {
    max-width: unset;
    // text-align: center;
    justify-content: center;
  }

  .logo {
    width: auto;
    height: 50px;
    cursor: pointer;
  }

  & + .main-content {
    background-color: #F4F4F4;
  }
}
